<template>
  <screen_width_cont>
    <div class="cont_300_basico ancho_acotado">
      <div class="cotizadorFinalizar">
        <div class="cotizadorFinalizarCard">
          <div class="header_top">
            <!-- <headerBack @back="backLocal" /> -->
          </div>

          <div class="cotizadorFinalizarCheck">
            <img :src="iconCotizadorFinalizarCheckAzul" alt="" srcset="" />
          </div>

          <div class="cotizadorFinalizarText1 text_basico f_w_500">
            {{ t("app.cotizadorFinalizar.tit") }}
          </div>

          <div class="cotizadorFinalizarText2">
            {{ t("app.cotizadorFinalizar.text_1") }}
          </div>

          <div class="cotizadorFinalizarBtn">
            <btnAction
              :textBtn="t('app.comunes.btns.finalizar')"
              @action="finalizar"
              colorBack="azul"
              colorText="blco"
              :estadoBtn="estadoBtn"
            />
          </div>
        </div>
      </div>
    </div>
  </screen_width_cont>
</template>

<script>
// import { IonContent, IonPage } from "@ionic/vue";
import { useI18n } from "vue-i18n";
import { mapMutations, mapState } from "vuex";
import btnAction from "../components/generales/btnAction.vue";
import screen_width_cont from "../components/menuSp/screen_width_cont.vue";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  name: "cotizadorFinalizar",
  components: {
    btnAction,
    screen_width_cont,
  },
  data() {
    return {
      iconCotizadorFinalizarCheckAzul: require("../assets/imgs/iconVerificaCuponCheckAzul.svg"),
    };
  },
  computed: {
    ...mapState(["widthScreen"]),

    estadoBtn() {
      let aux = "active";
      // if(!this.errorSimulador){
      //   aux = "active";
      // }
      return aux;
    },
  },
  methods: {
    ...mapMutations(["setshowSpinner"]),
    
    finalizar() {
      this.setshowSpinner(false);
      this.$router.push({ name: "home", replace: true });
    },
    // backLocal(){
    //   this.$router.push({ name: "cotizadorDatos", replace: true });
    // },
  },
};
</script>

<style>
.cotizadorFinalizar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 100%;
  height: 100vh;
}
.cotizadorFinalizarCard {
  background: #ffffff;
  box-shadow: 0px 1px 11px 2px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  padding: 0 1.5rem 2rem 1.5rem;
}
.cotizadorFinalizarCheck {
  margin-top: 1rem;
  text-align: center;
}
.cotizadorFinalizarCheck img {
  width: 2.75rem;
}
.cotizadorFinalizarText1 {
  margin-top: 1.5625rem;
  /* font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem; */
  /* text-align: justify; */
  text-align: center;
  color: #614ad3;
}
.cotizadorFinalizarText2 {
  margin-top: 1.5625rem;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: justify;
  color: #787878;
}
.cotizadorFinalizarBtn {
  margin-top: 3.4375rem;
}
</style>